export const detect = (ua) => {
  if (!ua) {
    throw new Error('userAgent is undefined')
  }
  const isAndroid = /(?:Android)/.test(ua)
  const isFireFox = /(?:Firefox)/.test(ua)
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua))
  const isiPhone = /(?:iPhone)/.test(ua) && !isTablet
  const isPc = !isiPhone && !isAndroid
  const isPad = /(?:iPad)/.test(ua)
  return {
    isTablet,
    isiPhone,
    isAndroid,
    isPc,
    isPad
  }
}
