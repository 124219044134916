import Vue from 'vue'
import dayjs from 'dayjs'

import ENUM from '~/core/enum'
import Currency from '~/components/Currency'
import DataTable from '~/components/DataTable'
import Address from '~/components/Address'
import ExtUpload from '~/components/ExtUpload'

Vue.prototype.$dayjs = dayjs
Vue.prototype.$ENUM = ENUM

Vue.component('Currency', Currency)
Vue.component('DataTable', DataTable)
Vue.component('Address', Address)
Vue.component('ExtUpload', ExtUpload)

export default (ctx) => {
  ctx.$ENUM = ENUM
  ctx.$dayjs = dayjs
}
