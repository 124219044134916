const DESKTOP_VP =
  'width=1280,initial-scale=1.0,maximum-scale=3,user-scalable=yes'
const MOBILE_VP =
  'width=device-width,initial-scale=1.0,maximum-scale=1,user-scalable=no'

const MOBILE_LIST = [
  'index',
  'login',
  'register',
  'landing',
  'forget',
  'about',
  'news',
  'news-id',
  'aff-program',
  'contact',
  'qa',
  'black5',
  'm-upload',
  'bind-wechat'
]

// 初始化meta
const meta = document.createElement('meta')
meta.setAttribute('name', 'viewport')
meta.setAttribute('content', '')
document.head.appendChild(meta)

export default ({ app }) => {
  app.router.afterEach(({ name }) => {
    meta.setAttribute(
      'content',
      MOBILE_LIST.includes(name) ? MOBILE_VP : DESKTOP_VP
    )
  })
}
