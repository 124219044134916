// eslint-disable-next-line prettier/prettier
const createApi = ({ get, post, memoize }) => {
  return {
    // 登录
    login: post('/api/core/login/'),
    logout: post('/api/core/logout/'),
    register: post('/api/core/register/'),
    checkPhone: post('/api/core/register/check_phone/'),
    sendVerifyCode: post('/api/core/send_sms/'),
    forgetPwd: post('/api/core/user/forget_pwd/'),
    phoneLoginOrRegister: post('/api/core/phone_login_register/'),

    // user
    fetchUserDetail: get('/api/core/user/detail/'),
    updateUserInfo: post('/api/core/user/update_info/'),
    confirmUserInfo: post('/api/core/user/confirm_submit/'),
    bindingS2cUser: post('/api/core/user/binding/'),
    disBindingS2cUser: post('/api/core/user/dis_binding/'),
    changePwd: post('/api/core/user/update_pwd/'),
    updateUsername: post('/api/core/user/username_update/'),
    termsConditions: get('/api/core/terms_conditions/'),
    fetchBindWechatQrcode: get('/api/wechat/bind_qrcode/'),
    wechatBindUser: post('/api/wechat/bind_user/'),
    wechatUnbind: post('/api/wechat/unbind/'),

    // 地址
    fetchCountries: memoize(get('/api/address/country/list/')),
    fetchProvinces: memoize(get('/api/address/province/list/')),
    fetchCities: memoize(get('/api/address/city/list/')),
    fetchCounties: memoize(get('/api/address/county/list/')),
    fetchAddressList: get('/api/address/addressbook/'),
    updateAddress: post('/api/address/addressbook/'),

    // 余额
    fetchBalance: get('/api/core/account/balance/'),
    exportBalance: get('/api/core/account/balance/export/'),
    // exportBalance: () => {
    //   return `${process.env.API_URL}/api/core/account/balance/export/`
    // },

    // news messages
    fetchMessage: get('/api/core/message/'),
    readMessage: get('/api/core/message/detail/'),

    // purchase
    fetchPuchase: get('/api/purchase/purchase/'),
    updatePuchase: post('/api/purchase/purchase/'),
    delPuchaseInvoice: post('/api/purchase/purchase/'),
    fetchPuchaseDetail: get('/api/purchase/purchase_detail/'),
    fetchPuchaseStatus: get('/api/purchase/purchase_status/'),
    fetchPuchaseItem: get('/api/purchase/purchase_item/'),
    fetchPuchaseOrderDetail: get('/api/purchase/purchase-order-detail/'),
    fetchPuchaseOrder: get('/api/purchase/purchase-order/'),
    fetchRefundPuchase: get('/api/purchase/refund_purchase/'),
    updateRefundPuchase: post('/api/purchase/refund_purchase/'),
    exportPurchase: get('/api/purchase/export/'),
    fetchPurchaseRefundAmount: get('/api/purchase/purchase-refund-amount/'),

    deposit: post('/api/fusionpay/deposit/'),
    fetchBillingStatus: get('/api/fusionpay/paid/'),

    bankTransfer: post('/api/core/bank_transfer/'),
    fetchBankTransfer: get('/api/core/bank_transfer/'),
    commissionWithdraw: post('/api/core/commission_withdraw/'),
    fetchRateFree: get('/api/core/user/service_rate_free/'),
    checkDeposit: get('/api/core/billing/'),
    createUnionPay: post('/api/unionpays/unionpay/'),
    queryUnionPay: get('/api/unionpays/unionpay_query/'),

    // order
    fetchOrderList: get('/api/order/order-list/'),
    fetchOrderStatis: get('/api/order/order-statis/'),
    fetchOrderDetailByNumber: get('/api/order/detail/number/'),
    delOrder: (id, ...params) => post(`/api/order/cancel/${id}/`)(...params),
    fetchOrderDelivery: (id, ...params) =>
      get(`/api/order/shipping/${id}/`)(...params),
    saveOrderDelivery: (id, ...params) =>
      post(`/api/order/shipping/${id}/`)(...params),
    fetchOrderDetail: (id, ...params) =>
      get(`/api/order/detail/${id}/`)(...params),

    fetchDrawbackById: (id, ...params) =>
      get(`/api/order/rebate-btn/${id}/`)(...params),
    saveDrawbackById: (id, ...params) =>
      post(`/api/order/rebate-btn/${id}/`)(...params),
    fetchOrderById: (id, ...params) =>
      get(`/api/order/create/${id}/`)(...params),
    createOrUpdateOrder: (id, ...params) =>
      post(`/api/order/create/${id}/`)(...params),
    fetchOrderServices: get('/api/order/intlservices/'),
    fetchOrderServicePrice: get('/api/order/service-price/'),
    fetchTaxRefundPlan: get('/api/rebate/rebate-plan/'),

    // redirect url
    fetchRedirectUrl: get('/api/order/redirect-url/'),

    // cards
    fetchCard: get('/api/card/card/'),
    cardCharge: post('/api/card/card/'),
    fetchCardRecord: get('/api/card/card_record/'),
    applyCard: post('/api/card/card_apply/'),
    applyCardLog: get('/api/card/card_apply/'),
    updateapplyCard: post('/api/card/card_apply/update/'),

    // marketing
    fetchAffiliateLog: get('/api/marketing/affiliate_log/'),
    applyAffiliateCash: post('/api/marketing/affiliate_cash/'),
    fetchAffiliateRecommend: get('/api/marketing/affiliate_recommend/'),
    fetchAffiliateCommission: get('/api/marketing/affiliate_commission/'),

    // news
    fetchNews: get('/api/news/news/'),
    fetchNewsById: get('/api/news/news/'),
    fetchAobutUs: get('/api/news/about_us/'),
    fetchOurService: get('/api/news/our_service/'),
    fetchFQA: get('/api/news/fqa/'),
    fetchFQACate: get('/api/news/fqa_catrtory/'),
    fetchConnectUs: get('api/news/connect_us/'),
    fetchNewsCategory: get('/api/news/news_category/'),

    // banner
    fetchBanner: get('/api/news/banner/'),

    fetchNewBanner: get('/api/news/banner/'),
    // fetchNewsCategory: get('/api/newes/news_category'),

    // brands
    fetchBrands: get('/api/core/brands/')
  }
}

export default (ctx, inject) => {
  const { $get: get, $post: post, $apiMemoize: memoize } = ctx
  const api = createApi({
    get,
    post,
    memoize
  })

  inject('api', api)
  ctx.$api = api

  const API_URL = process.env.API_URL
  inject('API_URL', API_URL)
  ctx.$API_URL = API_URL
}
