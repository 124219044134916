import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

import VueQrcode from '@chenfengyuan/vue-qrcode'

import IconFont from '../iconfont'
import Tab from '../directives/tab'
import XImg from '../components/XImg'
import portal from './portal'

Vue.use(VueClipboard)
Vue.component(VueQrcode.name, VueQrcode)
Vue.component('IconFont', IconFont)
Vue.component('XImg', XImg)

Vue.use(Tab)
Vue.prototype.$portal = portal
