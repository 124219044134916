import { ref, watch, getCurrentInstance } from '@nuxtjs/composition-api'

export const useSyncValue = (refValue, action = 'input') => {
  const vm = getCurrentInstance().proxy
  const localValue = ref(refValue.value)

  watch(refValue, (value) => {
    localValue.value = value
  })

  watch(localValue, (value) => {
    vm.$emit(action, value)
  })

  const setValue = (value) => {
    localValue.value = value
  }
  return [localValue, setValue]
}

export const useVm = () => {
  return getCurrentInstance().proxy
}

export const useStore = () => {
  const vm = getCurrentInstance().proxy

  return vm.$store
}

export const useShowCustomer = () => {
  const store = useStore()

  return () => {
    const user = store.state?.user
    if (user) {
      window._MEIQIA('metadata', {
        id: user.id,
        email: user.email,
        username: user.username,
        phone: user.phone
      })
    }

    window._MEIQIA('showPanel')
  }
}
