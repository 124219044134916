export default ({ $ENUM, route, redirect, store }) => {
  const { meta, user } = store.state
  const { USER_STATUS } = $ENUM
  if (!meta || !meta.private) {
    return
  }

  if (!user && route.path !== '/login') {
    redirect('/login')
    return
  }

  // 进入锁定
  if (user.status === USER_STATUS.BLOCKED && route.path !== '/user-verify') {
    redirect('/user-verify?result=true')
  }
}
