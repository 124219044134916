export default ({ props }) => {
  const { rows, gutter = 10 } = props

  return (
    <Row class="form-render" gutter={gutter}>
      {rows.map(
        ({ node, rules, col = 24, label, prop, render, required }, index) =>
          render ? (
            <Col key={prop || index} span={24}>
              {render}
            </Col>
          ) : (
            <Col key={prop || index} span={col}>
              <FormItem
                rules={rules}
                label={label}
                prop={prop}
                required={required}
              >
                {node}
              </FormItem>
            </Col>
          )
      )}
    </Row>
  )
}
