import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3bc6c100 = () => interopDefault(import('../pages/account/balance.vue' /* webpackChunkName: "pages/account/balance" */))
const _75be8783 = () => interopDefault(import('../pages/account/balance/index.vue' /* webpackChunkName: "pages/account/balance/index" */))
const _569c02b3 = () => interopDefault(import('../pages/account/balance/affiliate-detail/index.vue' /* webpackChunkName: "pages/account/balance/affiliate-detail/index" */))
const _4b8a4f82 = () => interopDefault(import('../pages/account/balance/exchange.vue' /* webpackChunkName: "pages/account/balance/exchange" */))
const _86b0ddf2 = () => interopDefault(import('../pages/account/cards.vue' /* webpackChunkName: "pages/account/cards" */))
const _510ef64a = () => interopDefault(import('../pages/account/cards/index.vue' /* webpackChunkName: "pages/account/cards/index" */))
const _53ca0d26 = () => interopDefault(import('../pages/account/cards/card-detail.vue' /* webpackChunkName: "pages/account/cards/card-detail" */))
const _6922e289 = () => interopDefault(import('../pages/account/cards/detail.vue' /* webpackChunkName: "pages/account/cards/detail" */))
const _6593f7db = () => interopDefault(import('../pages/account/cards/register.vue' /* webpackChunkName: "pages/account/cards/register" */))
const _4b9a9d23 = () => interopDefault(import('../pages/account/cards/transfer.vue' /* webpackChunkName: "pages/account/cards/transfer" */))
const _9a3f791c = () => interopDefault(import('../pages/account/order.vue' /* webpackChunkName: "pages/account/order" */))
const _22962716 = () => interopDefault(import('../pages/account/order/index.vue' /* webpackChunkName: "pages/account/order/index" */))
const _0f9cece9 = () => interopDefault(import('../pages/account/order/create.vue' /* webpackChunkName: "pages/account/order/create" */))
const _afb4aabe = () => interopDefault(import('../pages/account/order/delivery.vue' /* webpackChunkName: "pages/account/order/delivery" */))
const _ff889c84 = () => interopDefault(import('../pages/account/order/detail.vue' /* webpackChunkName: "pages/account/order/detail" */))
const _6e9e930d = () => interopDefault(import('../pages/account/purchase.vue' /* webpackChunkName: "pages/account/purchase" */))
const _77a1dbd0 = () => interopDefault(import('../pages/account/purchase/index.vue' /* webpackChunkName: "pages/account/purchase/index" */))
const _14ecadc3 = () => interopDefault(import('../pages/account/purchase/detail.vue' /* webpackChunkName: "pages/account/purchase/detail" */))
const _2696ed2c = () => interopDefault(import('../pages/account/purchase/refund.vue' /* webpackChunkName: "pages/account/purchase/refund" */))
const _44344b73 = () => interopDefault(import('../pages/account/purchase/upload.vue' /* webpackChunkName: "pages/account/purchase/upload" */))
const _9d3d3942 = () => interopDefault(import('../pages/account/rebate-detail.vue' /* webpackChunkName: "pages/account/rebate-detail" */))
const _0e08a6a7 = () => interopDefault(import('../pages/account/success.vue' /* webpackChunkName: "pages/account/success" */))
const _ac5c0cc4 = () => interopDefault(import('../pages/aff-program.vue' /* webpackChunkName: "pages/aff-program" */))
const _1f31611a = () => interopDefault(import('../pages/after-tips.vue' /* webpackChunkName: "pages/after-tips" */))
const _175133bc = () => interopDefault(import('../pages/bind-wechat.vue' /* webpackChunkName: "pages/bind-wechat" */))
const _43bdcffa = () => interopDefault(import('../pages/black5/index.vue' /* webpackChunkName: "pages/black5/index" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _95b46dfe = () => interopDefault(import('../pages/cooperation.vue' /* webpackChunkName: "pages/cooperation" */))
const _723951d6 = () => interopDefault(import('../pages/en/index.vue' /* webpackChunkName: "pages/en/index" */))
const _443a5fd7 = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _3b4df8fc = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _8f3a5340 = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _49337b7a = () => interopDefault(import('../pages/qa.vue' /* webpackChunkName: "pages/qa" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _177ec73b = () => interopDefault(import('../pages/service.vue' /* webpackChunkName: "pages/service" */))
const _811604b0 = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _054608f6 = () => interopDefault(import('../pages/user-info.vue' /* webpackChunkName: "pages/user-info" */))
const _0406397e = () => interopDefault(import('../pages/user-verify.vue' /* webpackChunkName: "pages/user-verify" */))
const _167cb5a4 = () => interopDefault(import('../pages/m/notice.vue' /* webpackChunkName: "pages/m/notice" */))
const _53a7704d = () => interopDefault(import('../pages/m/upload.vue' /* webpackChunkName: "pages/m/upload" */))
const _7329c5ff = () => interopDefault(import('../pages/landing/components/BussinessStep1.vue' /* webpackChunkName: "pages/landing/components/BussinessStep1" */))
const _7337dd80 = () => interopDefault(import('../pages/landing/components/BussinessStep2.vue' /* webpackChunkName: "pages/landing/components/BussinessStep2" */))
const _7345f501 = () => interopDefault(import('../pages/landing/components/BussinessStep3.vue' /* webpackChunkName: "pages/landing/components/BussinessStep3" */))
const _73540c82 = () => interopDefault(import('../pages/landing/components/BussinessStep4.vue' /* webpackChunkName: "pages/landing/components/BussinessStep4" */))
const _73622403 = () => interopDefault(import('../pages/landing/components/BussinessStep5.vue' /* webpackChunkName: "pages/landing/components/BussinessStep5" */))
const _73703b84 = () => interopDefault(import('../pages/landing/components/BussinessStep6.vue' /* webpackChunkName: "pages/landing/components/BussinessStep6" */))
const _cde67290 = () => interopDefault(import('../pages/message/_id.vue' /* webpackChunkName: "pages/message/_id" */))
const _67925968 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about"
  }, {
    path: "/account",
    component: _83dfe29a,
    children: [{
      path: "",
      component: _cbd4fa14,
      name: "account"
    }, {
      path: "balance",
      component: _3bc6c100,
      children: [{
        path: "",
        component: _75be8783,
        name: "account-balance"
      }, {
        path: "affiliate-detail",
        component: _569c02b3,
        name: "account-balance-affiliate-detail"
      }, {
        path: "exchange",
        component: _4b8a4f82,
        name: "account-balance-exchange"
      }]
    }, {
      path: "cards",
      component: _86b0ddf2,
      children: [{
        path: "",
        component: _510ef64a,
        name: "account-cards"
      }, {
        path: "card-detail",
        component: _53ca0d26,
        name: "account-cards-card-detail"
      }, {
        path: "detail",
        component: _6922e289,
        name: "account-cards-detail"
      }, {
        path: "register",
        component: _6593f7db,
        name: "account-cards-register"
      }, {
        path: "transfer",
        component: _4b9a9d23,
        name: "account-cards-transfer"
      }]
    }, {
      path: "order",
      component: _9a3f791c,
      children: [{
        path: "",
        component: _22962716,
        name: "account-order"
      }, {
        path: "create",
        component: _0f9cece9,
        name: "account-order-create"
      }, {
        path: "delivery",
        component: _afb4aabe,
        name: "account-order-delivery"
      }, {
        path: "detail",
        component: _ff889c84,
        name: "account-order-detail"
      }]
    }, {
      path: "purchase",
      component: _6e9e930d,
      children: [{
        path: "",
        component: _77a1dbd0,
        name: "account-purchase"
      }, {
        path: "detail",
        component: _14ecadc3,
        name: "account-purchase-detail"
      }, {
        path: "refund",
        component: _2696ed2c,
        name: "account-purchase-refund"
      }, {
        path: "upload",
        component: _44344b73,
        name: "account-purchase-upload"
      }]
    }, {
      path: "rebate-detail",
      component: _9d3d3942,
      name: "account-rebate-detail"
    }, {
      path: "success",
      component: _0e08a6a7,
      name: "account-success"
    }]
  }, {
    path: "/aff-program",
    component: _ac5c0cc4,
    name: "aff-program"
  }, {
    path: "/after-tips",
    component: _1f31611a,
    name: "after-tips"
  }, {
    path: "/bind-wechat",
    component: _175133bc,
    name: "bind-wechat"
  }, {
    path: "/black5",
    component: _43bdcffa,
    name: "black5"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact"
  }, {
    path: "/cooperation",
    component: _95b46dfe,
    name: "cooperation"
  }, {
    path: "/en",
    component: _723951d6,
    name: "en"
  }, {
    path: "/forget",
    component: _443a5fd7,
    name: "forget"
  }, {
    path: "/guide",
    component: _3b4df8fc,
    name: "guide"
  }, {
    path: "/landing",
    component: _8f3a5340,
    name: "landing"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/qa",
    component: _49337b7a,
    name: "qa"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/service",
    component: _177ec73b,
    name: "service"
  }, {
    path: "/terms-conditions",
    component: _811604b0,
    name: "terms-conditions"
  }, {
    path: "/user-info",
    component: _054608f6,
    name: "user-info"
  }, {
    path: "/user-verify",
    component: _0406397e,
    name: "user-verify"
  }, {
    path: "/m/notice",
    component: _167cb5a4,
    name: "m-notice"
  }, {
    path: "/m/upload",
    component: _53a7704d,
    name: "m-upload"
  }, {
    path: "/landing/components/BussinessStep1",
    component: _7329c5ff,
    name: "landing-components-BussinessStep1"
  }, {
    path: "/landing/components/BussinessStep2",
    component: _7337dd80,
    name: "landing-components-BussinessStep2"
  }, {
    path: "/landing/components/BussinessStep3",
    component: _7345f501,
    name: "landing-components-BussinessStep3"
  }, {
    path: "/landing/components/BussinessStep4",
    component: _73540c82,
    name: "landing-components-BussinessStep4"
  }, {
    path: "/landing/components/BussinessStep5",
    component: _73622403,
    name: "landing-components-BussinessStep5"
  }, {
    path: "/landing/components/BussinessStep6",
    component: _73703b84,
    name: "landing-components-BussinessStep6"
  }, {
    path: "/message/:id?",
    component: _cde67290,
    name: "message-id"
  }, {
    path: "/news/:id",
    component: _67925968,
    name: "news-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
