import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

export const countryMap = {
  CN: '中国大陆',
  DE: '德国',
  UK: '英国',
  HK: '香港',
  TW: '台湾',
  MO: '澳门',
  MY: '马来西亚',
  AU: '澳大利亚',
  US: '美国',
  SG: '新加坡',
  NZ: '新西兰',
  JP: '日本'
}

export const createSyncData = (localKey, propKey, eventKey = 'input') => {
  return {
    [localKey]: {
      deep: true,
      handler(value) {
        this.$emit(eventKey, value)
      }
    },
    [propKey]: {
      deep: true,
      immediate: true,
      handler(value) {
        if (isEqual(value, this[localKey])) {
          return
        }

        this[localKey] = cloneDeep(value)
      }
    }
  }
}
export const changeToPadViewPort = (width = 1280) => {
  const list = document.querySelectorAll('meta')
  list.forEach((item) => {
    if (item.content.includes('width=')) {
      item.content = `width=${width},initial-scale=${window.screen.width /
        width},maximum-scale=2,user-scalable=yes`
    }
  })
}

export const changeToMobileViewport = (vm) => {
  const createViewport = (content) => {
    const meta = document.createElement('meta')

    meta.setAttribute('name', 'viewport')
    meta.setAttribute('content', content)
    document.querySelector('head').appendChild(meta)
    return meta
  }

  const removeViewport = () => {
    return document.createElement('meta')?.getAttribute('content')
  }

  const oldContent = removeViewport()
  createViewport('width=device-width,initial-scale=1.0,user-scalable=no')

  vm.$once('hook:beforeDestroy', () => {
    removeViewport()
    createViewport(oldContent)
  })
}

export const addressStringify = (obj) => {
  if (!obj) {
    return ''
  }

  const country = obj.country

  const fields =
    country === 'CN'
      ? [
          countryMap[country],
          obj.province,
          obj.city,
          obj.county,
          obj.street1,
          obj.postcode ? `(${obj.postcode})` : ''
        ]
      : [
          countryMap[country],
          obj.city,
          obj.province,
          obj.street1,
          obj.street2,
          obj.street3,
          obj.postcode ? `(${obj.postcode})` : ''
        ]

  return fields.filter(Boolean).join(' ')
}
