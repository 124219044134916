import bus from '~/utils/bus'

export default (ctx) => {
  const { $axios, app } = ctx

  ctx.$get = (url) => {
    return (params, options) => {
      return $axios.get(url, {
        params,
        ...options
      })
    }
  }

  ctx.$post = (url) => {
    return (data = {}, options) => {
      return $axios.post(url, data, options)
    }
  }

  ctx.$remove = (url) => {
    return (params, options) => {
      return $axios.delete(url, {
        params,
        ...options
      })
    }
  }

  ctx.$apiMemoize = (api) => {
    const cacheMap = {}

    return async (...args) => {
      const token = JSON.stringify(args)
      const cache = cacheMap[token]

      if (cache) {
        return Promise.resolve(cache)
      } else {
        cacheMap[token] = await api(...args)
        return cacheMap[token]
      }
    }
  }

  // http config
  const TIMEOUT_TIME = 30000
  $axios.defaults.timeout = TIMEOUT_TIME
  $axios.defaults.withCredentials = true
  $axios.defaults.baseURL = process.env.API_URL

  // http request 拦截
  $axios.interceptors.request.use(
    (config) => {
      // 如果有token，每次请求都加在 headers.Authorization
      const token = app.$cookies.get('token')

      if (!/login|register/.test(config.url) && token) {
        config.headers.Authorization = `JWT ${token}`
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // http response 拦截
  $axios.interceptors.response.use(
    ({ data }) => {
      const isBizApi = data && typeof data.code !== 'undefined'
      if (!isBizApi) {
        return data
      }

      const isError = isBizApi && data.code !== 0

      if (isError) {
        return Promise.reject(data)
      }

      return data.data
    },
    (error) => {
      if (process.client && error.response) {
        switch (error.response.status) {
          case 400:
          case 401:
            // 授权失效，登出
            bus.$emit('logout')
            break
        }
      }

      return Promise.reject(error)
    }
  )

  // http response error 拦截器
  $axios.interceptors.response.use(null, (error) => {
    if (process.client) {
      bus.$Message.error({
        background: true,
        duration: 6,
        content: error.detail || error.desc || error.toString()
      })
    }

    return Promise.reject(error)
  })
}
