export const state = () => ({
  user: null,
  isNewUser: true,
  token: null,
  cards: [],
  meta: {}
})

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_IS_NEW_USER(state, flag) {
    state.isNewUser = flag
  },
  SET_CARDS(state, cards) {
    state.cards = cards
  },
  SET_META(state, meta) {
    state.meta = meta
  }
}

export const actions = {
  async updateUserDetail({ commit }) {
    const token = this.app.$cookies.get('token')
    if (!token) {
      return
    }

    try {
      const [data, { data: cardData }] = await Promise.all([
        this.$api.fetchUserDetail(undefined, {
          headers: {
            Authorization: `JWT ${token}`
          }
        }),
        this.$api.fetchCard()
      ])

      commit('SET_TOKEN', token)
      commit('SET_USER', data)
      // 如果没有申请消费卡则是新用户
      commit('SET_IS_NEW_USER', !cardData?.length)
      commit('SET_CARDS', cardData || [])

      this.$sentry &&
        this.$sentry.setUser({
          id: data.id,
          email: data.email || '',
          username: data.username || ''
        })
    } catch (err) {
      this.$cookies.remove('token', { path: '/' })
    }
  },

  nuxtServerInit({ dispatch }) {
    return dispatch('updateUserDetail')
  }
}
