// 如只需要tab切换 只需要在dom上增加 v-tab 如果需要元素上下切换需要增加一个表示 例入 v-tab="demo"

const TAB = 9
const UP = 38
const DOWN = 40
export default {
  install(Vue) {
    Vue.directive('tab', {
      bind: (el, bidding) => {
        let addClassName = 'tab-choose'
        if (bidding.value) {
          addClassName += ` tab-choose-${bidding.value}`
        }
        el.className = `${el.className} ${addClassName}`
      },
      inserted: (el, bidding) => {
        el.addEventListener('keydown', (e) => {
          if (e.keyCode === TAB) {
            inputFocus(
              e.keyCode,
              e,
              document.querySelectorAll('.tab-choose input'),
              el.querySelector('input')
            )
          } else if (e.keyCode === UP || e.keyCode === DOWN) {
            inputFocus(
              e.keyCode,
              e,
              bidding.value
                ? document.querySelectorAll(
                    `.tab-choose-${bidding.value} input`
                  )
                : document.querySelectorAll('.tab-choose input'),
              el.querySelector('input')
            )
          }
        })

        function inputFocus(keyCode, event, elList, currentEl) {
          const keyCodes = [TAB, UP, DOWN]
          if (!keyCodes.includes(keyCode)) return false

          event.stopPropagation()
          event.preventDefault()

          const arr =
            keyCode !== 9
              ? Array.from(elList)
              : Array.from(elList).filter((item) => !item.disabled)
          const len = arr.length
          const index = arr.findIndex((m) => m === currentEl)
          let nextEL = null
          if (keyCode === TAB && index < len - 1) {
            // tab键
            nextEL = arr[index + 1]
          } else if (keyCode === UP && index > 0) {
            // 上键
            nextEL = arr[index - 1]
          } else if (keyCode === DOWN && index < len - 1) {
            // 下键
            nextEL = arr[index + 1]
          }
          if (nextEL) {
            nextEL.focus()
            nextEL.select()
          }
        }
      }
    })
  }
}
