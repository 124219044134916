export default {
  USER_STATUS: {
    UNSUBMITTED: 0,
    WAITING: 5,
    EXPIRED: 7,
    REJECT: 10,
    BLOCKED: 15,
    PASS: 20
  },
  CARD_STATUS: {
    0: '创建中',
    1: '申请中',
    2: '停用',
    3: '有效使用',
    4: '待激活',
    5: '激活中'
  },
  CARD_RECORD_TYPE: {
    0: 'Debit',
    1: 'Credit'
  },
  CARD_RECORD_STATUS: {
    0: '已入账',
    1: '入账中'
  },
  PAY_METHOD: {
    40: '支付宝',
    50: '微信',
    51: '微信',
    80: '微信',
    5: '余额'
  },
  ID_TYPE: {
    0: '护照',
    1: '身份证'
  },
  INVOICE_TYPE: {
    1: '实体发票',
    2: '电子发票'
  },
  PURCHASE_TYPE: {
    0: '已过期',
    1: '待上传',
    4: '审核中',
    7: '待邮寄',
    10: '核对成功',
    13: '未通过',
    16: '未通过'
  },
  PURCHASE_STATUS: {
    DEPRECATED: 0,
    PENDING: 1,
    UPLOAD: 4,
    APPROVE: 7,
    RECONCILED: 10,
    DSIAPPROVED: 13,
    RECONFAIL: 16,
    TROUBLE: -1
  },
  PURCHASE_TRANSACTION_TYPE: {
    BUY: 0,
    REFUND: 1
  },
  ORDER_STATUS: {
    CREATED: 0,
    SHIPING: 1,
    COUNT_DOWN: 4,
    PROCESSED: 5
  },
  PAY_STATUS: {
    PENDING: '等待支付',
    PAID: '已支付'
  },
  ORDER_PROCESS_TYPE: {
    CREATED: 0,
    SHIPING: 1,
    CHECK: 2,
    AUDIT: 3,
    COUNT_DOWN: 4,
    PROCESSED: 5,
    PROCESSED_NO_REBATE: 6,
    CANCELED: 9
  },
  ORDER_PROCESS_STATUS: {
    0: '待发货',
    1: '待退税',
    2: '资料审核中',
    3: '返佣审核中',
    4: '返佣倒计时',
    5: '返佣已完成',
    6: '审核未通过',
    9: '已取消'
  },
  ORDER_RETAIN_STATUS: {
    0: '待支付',
    1: '已支付'
  },
  EXPRESS_NAME: {
    send2china: '速递中国',
    other: '其他'
  },
  COUNTRY: {
    CN: '中国',
    cn: '中国',
    HK: '香港',
    TW: '台湾',
    UK: '英国'
  },
  PURCHASE_ALL: {
    1: '全部',
    0: '部分'
  },
  PAY_STATUS_RETAIN: {
    0: '待支付',
    1: '已支付'
  },
  // eslint-disable-next-line prettier/prettier
  AFFILIATE_LOG_STATUS: {
    0: '等待中',
    1: '已通过',
    2: '未通过',
    3: '订单取消'
  },
  AFFILIATE_RECOMMEND_STATUS: {
    0: '未达标',
    1: '已达标',
    2: '已返佣'
  },
  BANK_TRANSFER_STATUS: {
    0: '等待付款',
    1: '已完成',
    2: '已取消'
  },
  RECEIVING_TYPE_MAP: {
    balance: 1,
    bank: 2
  },
  RECEIVING_TYPE: {
    1: 'iShopper预订金账户',
    2: '银行卡'
  }
}
