// eslint-disable-next-line prettier/prettier
const createApi = ({ get }) => {
  return {
    fetchProducts: get(process.env.SKU_API_URL + '/api/products'),
    fetchSku: get(process.env.SKU_API_URL + '/api/sku')
  }
}

export default (ctx, inject) => {
  const { $get: get, $post: post, $apiMemoize: memoize } = ctx
  const skuApi = createApi({
    get,
    post,
    memoize
  })

  inject('skuApi', skuApi)
  ctx.$skuApi = skuApi

  const SKU_API_URL = process.env.SKU_API_URL
  inject('SKU_API_URL', SKU_API_URL)
  ctx.$SKU_API_URL = SKU_API_URL
}
